<template>
  <div ref="mescroll"
       class="mescroll"
       :style="{ bottom:['/zhBookList','/zhebooklist','/zhMagazineList'].includes($route.path)?'50px':0,bottom:['/index_cn'].includes($route.path)?'80px':0,top:zhauthors?'50px':'143px'}">
    <div>
<!--       :style="zhauthors?'top: 50px;':'top: 50px;'" -->
      <slot name="down"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      size: {
        type: Number,
        default: 10
      },
      zhauthors:{
        type:[Number,String,Boolean],
        default:false
      }
    },
    data() {
      return {
        mescroll: null /**实例对象 */
      }
    },
    methods: {
      /************************************************************
       *
       * 下拉刷新回调
       *
       ************************************************************/
      downCallback() {
        console.log(1)
        this.$emit('downCallback')
      },
      /************************************************************
       *
       * 上拉加载回调
       *
       ************************************************************/
      upCallback() {
        console.log(2)
        var that = this
        this.$emit('upCallback')
      }
    },
    mounted() {
      var that = this
      this.mescroll = new MeScroll(this.$refs.mescroll, {
        //解析: down.callback默认调用mescroll.resetUpScroll(),而resetUpScroll会将page.num=1,再触发up.callback
        down: {
          auto: true, // 是否在初始化完毕之后自动执行下拉回调callback; 默认true
          callback: this.downCallback //下拉刷新的回调,别写成downCallback(),多了括号就自动执行方法了
        },
        up: {
          auto: false, // 是否在初始化时以上拉加载的方式自动加载第一页数据; 默认false
          callback: this.upCallback, //上拉加载的回调
          //以下是一些常用的配置,当然不写也可以的.
          page: {
            num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
            size: that.size //每页数据条数,默认10
          },
          noMoreSize: 1000000 //如果列表已无数据,可设置列表的总数量要大于5才显示无更多数据;
        }
      })
      window.mescroll = this.mescroll
    },
    activated: function () {
      window.mescroll = this.mescroll
    },
  }
</script>
<style lang="less">
  .mescroll {
    position: fixed;
    top: 143px;
    height: auto;
    /*如设置bottom:50px,则需height:auto才能生效*/
    //.mescroll-upwarp,.downwarp-content{
    //  visibility: hidden!important;
    //}
  }
  //.mescroll2 {
  //  position: fixed;
  //  top: 50px;
  //  height: auto;
  //  /*如设置bottom:50px,则需height:auto才能生效*/
  //  .mescroll-upwarp,.downwarp-content{
  //    visibility: hidden!important;
  //  }
  //
  //}

</style>
